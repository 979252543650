.container,
.mobContainer {
  position: sticky;
  top: 0;
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: white;
  z-index: 10;
  transition: all 0.1s ease;
}

#leftCut {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 50%;
  transform: translate(-40%, -30%);
  pointer-events: none;
}

.content {
  display: flex;
  align-items: center;
  gap: 4rem;
  height: 100%;
  overflow: hidden;
  z-index: 10;
}

.logo {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}


.rightHeader {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightHeader .icon {
  font-size: 24px;
}

.socials,
.links {
  display: flex;
  align-items: center;
}

.links {
  list-style: none;
  height: 100%;
  gap: 32px;
}

.links li {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #323232;
  cursor: pointer;
}

.links li:hover {
  color: rgb(2 140 140);
  padding-bottom: 0.3rem;
  border-bottom: 3px solid rgb(2 140 140);
}

.socials {
  gap: 24px;
}

.icon {
  color: rgb(205, 68, 105);
  font-size: 24px;
  cursor: pointer;
}

.cta {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

#topCta {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.mobContainer {
  display: none;
}

@media only screen and (max-width: 840px) {
  .content {
    gap: 2rem;
  }

  .links {
    gap: 20px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 0 1.2rem;
  }

  .logo {
    font-size: 20px;
    font-weight: 500;
  }

  .socials {
    gap: 16px;
  }

  .icon {
    font-size: 20px;
    color: gray;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    display: none;
  }

  .mobContainer {
    display: flex;
    z-index: 30;
    padding: 0 1.2rem;
  }

  .overlay {
    position: fixed;
    width: 100%;
    top: var(--nav-height);
    height: calc(100vh - var(--nav-height));
    background-color: #070707af;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .showOverlay {
    pointer-events: all;
    opacity: 1;
  }

  .content {
    padding: 2rem;
    position: fixed;
    top: var(--nav-height);
    left: 0;
    transform: translateX(-100%);
    height: calc(100vh - var(--nav-height));
    background-color: white;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    gap: 24px;
    transition: transform 0.3s ease;
  }

  .showContent {
    transform: translateX(0);
  }

  .links {
    margin-top: 5rem;
    flex-direction: column;
    height: max-content;
    align-items: flex-start;
    z-index: 11;
  }

  .links li {
    height: max-content;
    font-size: 20px;
    border: none;
    font-weight: 400;
  }

  .links li:hover {
    border: none !important;
  }

  .active {
    border: none !important;
  }

  .cta {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .socials {
    width: 100%;
    margin-top: 1rem;
    padding-top: 2rem;
    border-top: 1px solid #dcdcdc;
    justify-content: center;
    gap: 28px;
  }

  .icon {
    font-size: 24px;
  }

  .menu {
    font-size: 24px;
    cursor: pointer;
  }
}
