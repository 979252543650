.container {
  padding-top: 6rem;
  padding-bottom: 6rem;
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.container h1 {
  text-align: center;
  font-size: 3rem;
  line-height: 1.25;
  color: var(--secondry);
}

.container span {
  background: -webkit-linear-gradient(
    72deg,
    #cf1818 0%,
    rgb(140, 56, 196) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container p {
  text-align: center;
  color: var(--secondry);
  line-height: 1.25;
}

.container button {
  margin-top: 2rem;
  font-size: 1.1rem;
  padding: 12px 20px;
  background-color: rgb(2 140 140);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
}

.container img {
  margin-top: 2rem;
  width: 90%;
  border-radius: 10px;
}

.clientHeading p{
font-size: 1.5rem;
font-weight: 200;
}
.clients {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.clients img {
  width: 100px;
  height: auto;
}


@media (width < 720px) {
  .container {
    padding-top: 3rem;
  }

  .container h1 {
    font-size: 2.5rem;
  }
}
