#services {
  display: flex;
  background-color: rgb(196 223 254);
  flex-direction: column;
  color: black;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
}

.serviceText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: -2rem;
  font-size: 2rem;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  color: #686868;
}

.text {
  max-width: 800px;
  width: 90%;
  font-weight: 300;
  text-align: center;
}

.card-container {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 95%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.card {
  width: 1005;
  max-width: 320px;
  background-color: #fff;
  border-radius: 10px;
  background-color: #faf9f9;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    transition: all 0.6s ease;
}
.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.card:hover {
  background-color: #f4f4f4;
  box-shadow: rgba(67, 201, 235, 0.199) -10px 10px, 
  rgba(67, 201, 235, 0.19) -20px 20px ;
}

.card-content {
  padding-top: 12px;
}

.card-content h2 {
  color: #0078c2;
  font-size: 18px;
  margin-bottom: 6px;
}
.card-content p {
  color: #585858;
  line-height: 1.25;
  font-weight: 300;
}

@media (width < 720px) {
  #services {
    padding: 0 2rem;
  }

  .card {
    max-width: initial;
  }
}
