.container {
  width: 80%;
  border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background-color: #faf9f9;
  padding: 4rem;
  margin: 0 auto;
  margin-block: 2rem;
}

.stats h3 {
  font-weight: 600;
  font-size: 14px;
  color: #808080;
}

.stats p {
  font-size: 32px;
  color: rgb(238, 51, 101);
}

@media (width<720px) {
  .container {
    gap: 2rem;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
    background-color: transparent;
  }
}
