.shorts {
  background-image: linear-gradient(rgb(215 214 254),rgb(232, 247, 248));
  padding: 6rem;
  display: flex;
  color: var(--secondry);
  flex-direction: column;
}

.shorts > h2 {
  margin: 0 auto;
  margin-top: -2rem;
  font-size: 2rem;
  font-weight: 500;
  color:#686868 ;
}

.short {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  padding-top: 30px;
}

.leftShort h2 {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}

.leftShort h2 span {
  color: #686868;
}

.leftShort,
.rightShort {
  flex: 1;
  z-index: 2;
}

.leftShort p {
  line-height: 2;
  margin-top: 1rem;
  text-align: center;

}

.rightShort {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightShort img {
  width: 90%;
  margin: 0 auto;
  border-radius: 20px;
}

.lastInfo{
  padding-top: 50px;
  text-align: center;
}
.container {
  width: 80%;
  border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background-color: #faf9f9;
  padding: 4rem;
  margin: 0 auto;
  margin-block: 2rem;
}

.stats h3 {
  font-weight: 600;
  font-size: 14px;
  color: #808080;
}

.stats p {
  font-size: 32px;
  color: rgb(238, 51, 101);
}

@media (width < 720px) {
  .short {
    flex-direction: column-reverse !important;
  }

  .leftShort h2 {
    font-size: 2rem;
  }
  .container {
    gap: 2rem;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    background-color: transparent;
  }
}
