:root {
  --primary: #040608;
  --secondry: #323232;
  --nav-height: 60px;
  --border: 1px solid #434343;
  --hover: #0e0e0e;
  --link: #0d7ae0;
}

body,
html {
  background-color: white;
}

button {
  cursor: pointer;
}

button:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.primaryBtn {
  padding: 20px 32px;
  background-color: rgb(2 140 140);
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 50px;
}

.reverse {
  flex-direction: row-reverse;
}

.logoImg{
  height: auto;
  width: 150px;
}

.hrstyle{
  border-top: 1px solid #8c8b8b;
  width: 100%;
}