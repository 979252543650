.footer {
  --footer-padding: 4rem;
  background-color: #f0f1f3;
  display: flex;
  align-items: stretch;
  position: relative;
}

#bottomCut {
  pointer-events: none;
  z-index: 1;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  gap: 16px;
}

.form input,
.form textarea {
  height: 50px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  background-color: white;
  outline: none;
  border: 1px solid rgb(189, 183, 173);
  border-radius: 10px;
  font-size: 16px;
}

.form textarea {
  height: initial;
}
.form input:focus{
  color:#929292;
  border: 0.1rem solid rgb(2 140 140);
}
.form textarea:focus{
  color:#929292;
border: 0.1rem solid rgb(2 140 140);
}

.form button {
  height: 50px;
  width: 100%;
  padding: 12px 24px;
  color: white;
  background-color: rgb(2 140 140);
  border: none;
  border-radius: 50px;
  font-size: 16px;
}

.first {
  flex: 6;
}

.newsletter {
  flex: 4;
  padding: var(--footer-padding);
  border-left: 1px solid #909090;
  display: flex;
  flex-direction: column;
}

.brand {
  display: flex;
  justify-content: space-between;
  padding: var(--footer-padding);
}

.brand h3 {
  font-size: 32px;
  font-weight: 500;
}

.brand p {
  color: gray;
  width: 50%;
}

.links {
  border-top: 1px solid #909090;
  padding: var(--footer-padding);
  display: flex;
  gap: 4rem;
}

.links ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.links ul li {
  color: rgb(44, 44, 44) !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.links ul li:hover {
  color: var(--link);
}

.links ul li:first-child {
  cursor: initial;
  font-size: 18px;
}

.newsletter p {
  color: #161616;
  font-size: 14px;
  margin-bottom: 12px;
}

.newsletter h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.linkIcon {
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .footer {
    flex-direction: column-reverse;
    --footer-padding: 1.6rem;
  }

  .form {
    width: 100%;
    gap: 12px;
  }

  .form input {
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
  }

  .form button {
    padding: 0 24px;
  }

  .brand {
    flex-direction: column;
  }

  .brand p {
    width: 100%;
  }

  .links {
    gap: 0;
    justify-content: space-between;
  }

  .col:last-child {
    border-left: none;
    border-bottom: 1px solid #909090;
  }
}
